/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import ReactCompareImage from "react-compare-image";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smallQty: 0,
      largeQty: 0

    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

//function LandingPage() {
  render() {

    let img1Src = require("assets/img/without-bullboard.jpg");
    let img2Src = require("assets/img/with-bullboard.jpg");
    let bb48Src = require("assets/img/bb-4-8.jpg");
    let bb44Src = require("assets/img/bb-4-4.jpg");
    return (
      <>
        <ExamplesNavbar />
        <LandingPageHeader />
        <div className="main">
          <div className="section text-center">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2>What is Bullboard?</h2>
                  <h5 className="description text-left">
                    Bullboard is a manufactured panel engineered specifically for
                    boarding up both residential and commercial windows and doors.
                    Compared to plywood or OSB, Bullboard is stronger, lighter and
                    far more impervious to the elements. It is also better looking
                    and easier to paint.
                  </h5>
                  <h2>Bullboard Value</h2>
                  <h5 className="description text-left">
                    Because Bullboard is light and easy to work with, the total
                    job cost of a board-up will be less than the same job done
                    with plywood. Lower cost of materials plus labor for a higher
                    quality job equals pure win.
                  </h5>
                  <br />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col md="3">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="fas fa-flag-usa" />
                    </div>
                    <div className="description">
                      <h4>Made in the USA</h4>
                      <p className="description text-left">
                        Bullboard is made in the USA. Bullboard uses a distributed
                        manufacturing model, so not only does your money stay in
                        the US, it stays close to home.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="fas fa-trophy" />
                    </div>
                    <div className="description">
                      <h4>Better in Every Way</h4>
                      <p className="description text-left">
                        Bullboard is stronger, lighter and easier to work with.
                        This allows fewer workers to finish a board-up in less
                        time. Bullboard is easier to cut into complex shapes, and
                        very easy to paint. A boarded-up building shouldn't be an
                        eyesore!
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="fas fa-rocket" />
                    </div>
                    <div className="description">
                      <h4>Beyond Basic</h4>
                      <p className="description text-left">
                        Because Bullboard is an engineered product, it can be made
                        with extra features built in. Magnetic mounting, LED light
                        options, and integrated solar panels are just a few of the
                        upgrades coming soon.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="fas fa-ruler-combined" />
                    </div>
                    <div className="description">
                      <h4>Custom Options</h4>
                      <p className="description text-left">
                        Have an application where standard 4x8 boards don't quite
                        cut it? Bullboard can custom make panels to your specs.
                        On site fabrication is also possible, allowing unparalleled
                        flexibility for otherwise tricky installations.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section section-dark text-center">
            <Container>
              <h2>Compare and Contrast</h2>
              <p>Use the slider to see what a typical board-up looks like compared
              to a Bullboard-up.</p>
              <Row>
                <Col md="2">

                </Col>
                <Col md="8">
                  <div style={{width: "100%"}}>
                    <ReactCompareImage leftImage={img1Src} rightImage={img2Src} />
                  </div>
                </Col>
                <Col md="2">

                </Col>
              </Row>
            </Container>
          </div>
          <div className="section landing-section" id="bbCart">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="text-center">Order BullBoard</h2>
                  <h5>Perfectly suited to replace plywood or OSB for all your board-up needs, BullBoard is lighter, stronger and more environmentally resistant. Select from 4'X4' or 4'X8' panels below. Alternatively, feel free to describe custom needs. Please understand that pricing will depend on quantity desired, location to be delivered, and turnaround time expected.</h5>
                  <Form className="contact-form" action="https://baywebtech.com/bbcontact.php" method="post">
                    <Row>
                      <Col md="6">
                        <h5>Standard BullBoard - 4'X4' 7/16" thick.</h5>
                        <h4>$44.99/sheet</h4>
                        <img src={bb44Src} /><br/><br/>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">QTY @ 4'X4'</InputGroupAddon>
                          <Input min={0} max={100} type="number" step="1" name="smallQty" value={this.state.smallQty} onChange={this.handleInputChange} />
                        </InputGroup>
                      </Col>
                      <Col md="6">
                        <h5>Standard BullBoard - 4'X8' 7/16" thick.</h5>
                        <h4>$74.99/sheet</h4>
                        <img src={bb48Src} /><br/><br/>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">QTY @4'X8'</InputGroupAddon>
                          <Input min={0} max={100} type="number" step="1" name="largeQty" value={this.state.largeQty} onChange={this.handleInputChange} />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <h2>Estimated Total: ${(Number(this.state.smallQty) * 44.99 + Number(this.state.largeQty) * 74.99).toFixed(2) }</h2>
                        <p>Note: This total does not include shipping, handling or sales tax (if applicable). Standard delivery is 4-6 weeks, rush orders may be available with an additional processing fee.</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <label>Name</label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input name="name" placeholder="Name" type="text" />
                        </InputGroup>
                      </Col>
                      <Col md="6">
                        <label>Email</label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input name="email" placeholder="Email" type="text" />
                        </InputGroup>
                      </Col>
                    </Row>
                    <label>Additional info: If you have custom requirements, let us know! Any other special considerations or specifications can also be listed here.</label>
                    <Input
                      placeholder=""
                      type="textarea"
                      rows="4"
                      name="message"
                    />
                    <Row>
                      <Col className="ml-auto mr-auto" md="4">
                        <Button className="btn-fill" color="danger" size="lg">
                          Place Order
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="section section-dark landing-section">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="text-center">Contact us</h2>
                  <Form className="contact-form" action="https://baywebtech.com/bbcontact.php" method="post">
                    <Row>
                      <Col md="6">
                        <label>Name</label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input name="name" placeholder="Name" type="text" />
                        </InputGroup>
                      </Col>
                      <Col md="6">
                        <label>Email</label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input name="email" placeholder="Email" type="text" />
                        </InputGroup>
                      </Col>
                    </Row>
                    <label>Message</label>
                    <Input
                      placeholder="What can we help you with?"
                      type="textarea"
                      rows="4"
                      name="message"
                    />
                    <Row>
                      <Col className="ml-auto mr-auto" md="4">
                        <Button className="btn-fill" color="danger" size="lg">
                          Send Message
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        <DemoFooter />
      </>
    );
  }
}

export default LandingPage;
